var _a;
import key from './key';
import loadable from '@loadable/component';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./chanpay.reducer'); }
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'ChanpayView', component: function () { return import('./chanpay-view'); } }]; }
    },
    {
        point: '@@layers',
        prefix: '@chanpay',
        onload: function () { return import('./layers'); }
    },
    {
        resource: '@chanpay',
        value: (_a = {},
            _a['elements/account-management-person'] = loadable(function () { return import('./elements/account-management-person'); }),
            _a)
    }
];
