export var ID = '@chanpay';
export default {
    ID: ID,
    GET_CHANPAY_LIST: ID + "/GET_CHANPAY_LIST",
    PUT_ACCOUNT_PERSON: ID + "/PUT_ACCOUNT_PERSON",
    GET_REFRESH_LIST: ID + "/GET_REFRESH_LIST",
    GET_BANK_LIST: ID + "/GET_BANK_LIST",
    GET_PROVINCES_LIST: ID + "/GET_PROVINCES_LIST",
    GET_CITIES_LIST: ID + "/GET_CITIES_LIST",
    GET_BRANCH_LIST: ID + "/GET_BRANCH_LIST",
    POST_CHANPAY_CARD: ID + "/POST_CHANPAY_CARD",
    POST_PAYMENT: ID + "/POST_PAYMENT"
};
